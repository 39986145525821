// src/dataLoader.js
import Papa from 'papaparse';
/*import dataCSV from '.data/salgspriser.csv';*/

export const loadData = () => {
    return new Promise((resolve, reject) => {
      fetch(`./salgspriser.csv`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.text();
        })
        .then(csvText => {
          Papa.parse(csvText, {
            header: true,
            dynamicTyping: true,
            complete: results => resolve(results.data),
            error: err => reject(err),
          });
        })
        .catch(error => reject(error));
    });
  };
