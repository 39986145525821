// src/EstateResults.js
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';

function EstateResults({ result }) {
  const { zip, initialValue, currentValue, amountChange, percentageChange, priceData } = result;
  const ownedFrom = priceData[0].date;
  const ownedTo = priceData[priceData.length - 1].date;

  // Helper function to format date labels
const formatDate = dateStr => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  // Create a date object (Note: months are 0-indexed in JavaScript)
  const date = new Date(year, parseInt(month, 10) - 1, 1);
  // Format the date with Danish locale
  return date.toLocaleDateString('da-DK', { year: 'numeric', month: 'long' });
};

const numberFormatter = new Intl.NumberFormat('da-DK', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

// Percentage formatter
const percentageFormatter = new Intl.NumberFormat('da-DK', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

  // Helper function to format Y-axis labels
  const formatYAxis = value => {
    if (value >= 1e6) {
      // Millions
      return `${(value / 1e6).toFixed(1).replace('.', ',')} mio`;
    } else if (value >= 1e3) {
      // Thousands
      return `${(value / 1e3).toFixed(1).replace('.', ',')}k`;
    } else {
      // Hundreds or less
      return value.toString();
    }
  };

  return (
    <div className="my-4">
      <h2 className="mb-3">Resultat for postnummer: {zip}</h2>
      <div className="mb-3">
        <strong>Ejet fra:</strong> {formatDate(ownedFrom)}<br />
        <strong>Ejet til:</strong> {formatDate(ownedTo)}
      </div>
      <div className="mb-3">
        <strong>Start værdi:</strong>{' '}
        {initialValue !== null ? numberFormatter.format(initialValue) : 'N/A'}
      </div>
      <div className="mb-3">
        <strong>Nuværende værdi:</strong>{' '}
        {currentValue !== null ? numberFormatter.format(currentValue) : 'N/A'}
          
      </div>
      <div className="mb-3">
        <strong>Værdi forskel:</strong>{' '}
        {amountChange !== null ? numberFormatter.format(amountChange) : 'N/A'}
      </div>
      <div className="mb-3">
        <strong>Procentuel forskel:</strong>{' '}
        {percentageChange !== null ? percentageFormatter.format(percentageChange / 100): 'N/A'}
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={priceData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="date" tickFormatter={formatDate} />
          <YAxis
            tickFormatter={formatYAxis}
          />
          <Tooltip
            labelFormatter={formatDate}
            formatter={(value) => [numberFormatter.format(value), 'Værdi kr.']}
          />
          <CartesianGrid stroke="#f5f5f5" />
          <Line
            type="monotone"
            dataKey="totalValue"
            stroke="#007bff"
            yAxisId={0}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

// Helper function to format date labels
const formatDate = dateStr => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4);
  return `${year}-${month}`;
};

export default EstateResults;
