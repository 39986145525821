import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function InfoModal({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Information om Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Replace this with your actual information text */}
        <p>
        Bemærk, at der nogle måneder mangler tal for følgende bydele: Christianshavn, København NV, København SV og særligt Nordhavn. Det skyldes, at der ikke er nok data til at udregne den gennemsnitlige salspris per kvadratmeter i flere perioder. Hvis du har købt din bolig på et tidspunkt, hvor der ikke er data fra, vil du få værdien 0, og derfor vil din fortjeneste virke kunstigt høj.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Luk
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;