// src/EstateForm.js
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS

registerLocale('da', da);


function EstateForm({ onSubmit }) {
  const [zipCode, setZipCode] = useState('');
  const [estateSize, setEstateSize] = useState('');
  const [ownedFrom, setOwnedFrom] = useState('');
  const [ownedTo, setOwnedTo] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    if (!ownedFrom || !ownedTo) {
      alert('Vælg venligst en start og en slutdato');
      return;
    }

    // Convert Date objects to 'YYYY-MM' strings
    const ownedFromStr = ownedFrom.toISOString().slice(0, 7).replace('-', '');
    const ownedToStr = ownedTo.toISOString().slice(0, 7).replace('-', '');

    onSubmit(zipCode, estateSize, ownedFromStr, ownedToStr);
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="row">
        <div className="form-group col-md-6">
          <label htmlFor="zipCode">Postnummer</label>
          <input
            type="number"
            className="form-control"
            id="zipCode"
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="estateSize">Størrelse kvadratmeter</label>
          <input
            type="number"
            className="form-control"
            id="estateSize"
            value={estateSize}
            onChange={e => setEstateSize(e.target.value)}
            required
          />
        </div>
      </div>
  
      <div className="row">
        <div className="form-group col-md-5 mb-3">
          <label htmlFor="ownedFrom">Bolig ejet fra:</label>
          <DatePicker
            className="form-control"
            style={{ maxWidth: '100%' }}
            selected={ownedFrom}
            onChange={date => setOwnedFrom(date)}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            locale="da"
            placeholderText="Vælg måned og år"
            minDate={new Date(2010, 12)}
            maxDate={new Date(2024, 7)}
            id="ownedFrom"
          />
        </div>
        <div className="form-group col-md-5 offset-md-1 mb-3">
          <label htmlFor="ownedTo">Bolig ejet til:</label>
          <DatePicker
            className="form-control"
            style={{ maxWidth: '100%' }}
            selected={ownedTo}
            onChange={date => setOwnedTo(date)}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            locale="da"
            placeholderText="Vælg måned og år"
            minDate={new Date(2011, 2)}
            maxDate={new Date(2024, 9)}
            id="ownedTo"
          />
        </div>
      </div>
  
      <button type="submit" className="btn btn-primary mt-3">
        Udregn
      </button>
    </form>
  );
  
}

export default EstateForm;
