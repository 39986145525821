// src/App.js
import React, { useState, useEffect } from 'react';
import { loadData } from './dataLoader';
import EstateForm from './EstateForm';
import EstateResults from './EstateResults';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import InfoModal from './InfoModal';


import './App.css';



function App() {
  const [data, setData] = useState([]);
  const [zip, setZip] = useState('');
  const [size, setSize] = useState('');
  const [result, setResult] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    loadData().then(loadedData => setData(loadedData));
  }, []);

  const mapZipCode = (zip) => {
    zip = parseInt(zip, 10);
  
    if (zip > 1000 && zip < 1400) {
      return 1000;
    } else if (zip >= 1400 && zip < 1500) {
      return 1400;
    } else if (zip >= 1500 && zip < 1800) {
      return 1500;
    } else if (zip >= 1800 && zip < 2000) {
      return 1800;
    } else {
      // Handle ZIP codes outside these ranges
      return zip;
    }
  };

 // Inside App component
 const handleFormSubmit = (zipCodeInput, estateSize, ownedFrom, ownedTo) => {
  setZip(zipCodeInput);
  setSize(estateSize);

  // Map the entered ZIP code to the specified ranges
  const enteredZip = parseInt(zipCodeInput, 10);
  const mappedZip = mapZipCode(enteredZip);

  if (mappedZip === null) {
    alert('Det indtastede postnummer er ikke understøttet af vores data.');
    return;
  }

  // Get the estate data for the mapped ZIP code
  const estateData = data.find(item => item.ZIP === mappedZip);

  if (!estateData) {
    alert('Ingen data fundet for det justerede postnummer.');
    return;
  }

  // Inform the user if the ZIP code was adjusted
  // if (mappedZip !== enteredZip) {
  //   alert(`Det indtastede postnummer ${enteredZip} er justeret til ${mappedZip} for analysen.`);
  // }

  // Proceed with the rest of the function using estateData

  // Convert 'YYYY-MM' to 'YYYYMM' format to match data keys
  const ownedFromKey = ownedFrom.replace('-', '');
  const ownedToKey = ownedTo.replace('-', '');



  // Extract and filter time series data within the date range
  const timeKeys = Object.keys(estateData).filter(
    key => key !== 'ZIP' && key !== 'CITY'
  );

  const filteredTimeKeys = timeKeys.filter(
    key => key >= ownedFromKey && key <= ownedToKey
  );

  if (filteredTimeKeys.length === 0) {
    alert('Ingen data tilgængelig for den valgte periode.');
    return;
  }

  const priceData = filteredTimeKeys
    .map(key => {
      const pricePerSqm = estateData[key];
      const totalValue = pricePerSqm * parseFloat(estateSize);
      return {
        date: key,
        pricePerSqm: isNaN(pricePerSqm) ? null : pricePerSqm,
        totalValue: isNaN(totalValue) ? null : totalValue,
      };
    })
    .filter(entry => entry.totalValue !== null);

  if (priceData.length < 2) {
    alert('Ikke nok gyldige data inden for den valgte periode til at beregne ændringer.');
    return;
  }

  const initialValue = priceData[0].totalValue;
  const currentValue = priceData[priceData.length - 1].totalValue;
  const amountChange = currentValue - initialValue;

  let percentageChange;
  if (initialValue && initialValue !== 0) {
    percentageChange = (amountChange / initialValue) * 100;
  } else {
    percentageChange = null;
  }

  setResult({
    zip: mappedZip.toString(),
    initialValue,
    currentValue,
    amountChange,
    percentageChange,
    priceData,
  });
};


return (
  <div className="App">
    <div className="container">
      <h1 className="my-4 text-center">Udregn Boligsalgspriser
      <button
            type="button"
            className="btn btn-link p-0 ml-2 align-baseline"
            onClick={() => setShowInfoModal(true)}
            aria-label="Information"
            style={{ textDecoration: 'none' }}
          >
            <i className="bi bi-info-circle" style={{ fontSize: '1.5rem' }}></i>
          </button>
      </h1>
      <EstateForm onSubmit={handleFormSubmit} />
      {result && <EstateResults result={result} />}
      <InfoModal show={showInfoModal} onHide={() => setShowInfoModal(false)} />

    </div>
  </div>
);
}

export default App;